<template>
  <div class="main">
       <v-container>
           <p><strong> Account Details</strong> </p>
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
          v-model="fullname"
           background-color='#c9ebff'
           shaped
            label="FullName"
            filled
          ></v-text-field>
          <v-text-field
           background-color='#c9ebff'
           shaped
           disabled
             v-model="email"
            label="Email"
            filled
          ></v-text-field>
           <v-text-field
             v-model="phoneNumber"
            background-color='#c9ebff'
            shaped
            label="Phone Number"
            filled
          ></v-text-field>
        </v-col>
      </v-row>
         <div class="text-center">
            <v-btn
            @click="UpdateProfile()"
            color="#1c63ba"
            dark
            class="pl-10 pr-10 pt-5 pb-5"
            >
            Update changes
            </v-btn>
            <Loading v-if="Loading" class="mt-2"/>
        </div>
          <p><strong> Change Password</strong> </p>
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
           background-color='#c9ebff'
           shaped
           v-model="password"
            label="Old Password"
            filled
          ></v-text-field>
          <v-text-field
          v-model="Newpassword"
           background-color='#c9ebff'
           shaped
            label="New Password"
            filled
          ></v-text-field>
        </v-col>
      </v-row>
       <div class="text-center">
            <v-btn
            @click="UpdatePassword()"
            color="#1c63ba"
            dark
            class="pl-10 pr-10 pt-5 pb-5"
            >
            Save changes
            </v-btn>
        </div>
        <Loading v-if="Loading" class="mt-2"/>
    </v-container>
  </div>
</template>

<script>
import {UpdateUserProfile, changepassword} from '../APIs'
import axios from "axios";
import Loading from '../components/Loading'
export default {
    components:{
    Loading
  },
  created(){
   this.fullname = this.User.fullname
   this.email = this.User.email
   this.phoneNumber = this.User.phoneNumber
   this.walletBalance = this.User.walletBalance
  },
  data: () => ({
    fullname:"",
    email:"",
    phoneNumber:"",
    Newpassword:"",
    password:"",
    formattedNumber:"",
    walletBalance:""
  }),
  methods:{
    UpdateProfile(){
      this.$store.commit("setLoading", true)
          axios.put(`${UpdateUserProfile}`,{
            fullname:this.fullname,
            phoneNumber:this.formattedNumber,
          },
          {
               headers: { Authorization: "Bearer " + this.Token } 
          })
          .then(res => {    
              console.log(res.data.message, "PPP--") 
              this.$store.commit("setUser", res.data.user)        
              this.$store.commit("setSnackbar", true);
              this.$store.commit("setServerMessage", "Account Updated Successfully")
          })
          .catch(err => {
            console.log(err.response.data, "update Profile: ERR")
            this.$store.commit("setSnackbar", true);
            this.$store.commit("setServerMessage", `Err: ${err.response.data.message}`)
          })
          .finally(()=>{
             this.$store.commit("setLoading", false)
          })
    },
        UpdatePassword(){
        this.$store.commit("setLoading", true)
          axios.put(`${changepassword}`,{
            oldPassword:this.password,
            newPassword:this.Newpassword,
          },
          {
            headers: { Authorization: "Bearer " + this.Token } 
          })
          .then(res => {    
              console.log(res.data.message, "PPP--") 
              this.$store.commit("setToken", '')
              this.$router.push({name: "login"})      
              this.$store.commit("setSnackbar", true);
              this.$store.commit("setServerMessage", "Password Change Successfully")
          })
          .catch(err => {
            console.log(err.response.data, "update Profile: ERR")
            this.$store.commit("setSnackbar", true);
            this.$store.commit("setServerMessage", `Err: ${err.response.data.message}`)
          })
          .finally(()=>{
             this.$store.commit("setLoading", false)
          })
    }
  },
  computed:{
    Loading() {
      return this.$store.getters.Loading;
    },
  User() {
    return this.$store.getters.User;
  },
    Token() {
      return this.$store.getters.Token;
    },
},

}
</script>

<style scoped>
.main{
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 padding: 60px;
}

</style>